import convert from 'color-convert';
import {
  filterCategories,
  filterCategoryNames,
  PRODUCT_TYPE_PARAM,
} from '@/config/filter';
import { Color } from '@/types/common';
import type {
  Facets,
  Filter,
  FilterCategory,
  FilterCategoryName,
  FilterGroup,
  NestedFilter,
} from '@/types/list-page';

import type { LocationQuery } from 'vue-router';

const MAX_LIGHTNESS_FOR_WHITE_TEXT = 45;

const isNestedFilter = (filter: Filter): filter is NestedFilter => 'subItems' in filter;

const filterEnabledSubFilters = (filteredSubItems: Filter[]) => filteredSubItems
  .filter((item) => !('isDisabled' in item) || !item.isDisabled);

const areAllItemsSelected = (filteredSubItems: Filter[]) => filteredSubItems
  .every((subItem) => subItem.isSelected) && filteredSubItems.length > 0;

const areAllEnabledSubItemsSelected = (filteredSubItems: Filter[]) => areAllItemsSelected(
  filterEnabledSubFilters(filteredSubItems),
);

const isKeyInFacets = (key: string, facetPath: string, facets: Facets) => facets[`filter.${facetPath}`]
  && Object.keys(facets[`filter.${facetPath}`]).includes(key);

const createFilterWithEnabledStateForFacets = (
  facets: Facets,
  filterGroups: FilterGroup[],
) => filterGroups.map((filterGroup) => {
  const { key: filterGroupKey } = filterGroup;

  const filterCategory = filterCategories.find((category) => category.name === filterGroupKey);
  const allowMultipleValuesSelected = !!filterCategory?.allowMultipleValuesSelected;

  return [
    'productColor',
    'designType',
  ].includes(filterGroupKey)
    ? filterGroup
    : {
      ...filterGroup,
      filters: filterGroup.filters.map((parent: Filter) => {
        const { key: parentFilterKey } = parent;

        return {
          ...parent,
          isDisabled: !isKeyInFacets(parentFilterKey, filterGroupKey, facets) && !allowMultipleValuesSelected,
          subItems: isNestedFilter(parent)
            ? parent.subItems.map((subItem) => {
              const { key: subItemFilterKey } = subItem;
              const isSubItemDisabled = !isKeyInFacets(subItemFilterKey, `${filterGroupKey}.${parentFilterKey}`, facets);

              return {
                ...subItem,
                isDisabled: isSubItemDisabled && !allowMultipleValuesSelected,
                ...(isSubItemDisabled && { isSelected: false }),
              };
            })
            : [],
        };
      }),
    };
});

/**
 * returns dark or white color depending on the complementary hex value
 * @param {string} colorAsHex
 * @return {string}
 */
const getComplementaryColor = (colorAsHex: string) => {
  const lightness = convert.hex.xyz(colorAsHex)[1];

  const complementaryColor = lightness < MAX_LIGHTNESS_FOR_WHITE_TEXT
    ? Color.WHITE
    : Color.DARK;

  return complementaryColor;
};

/**
 * check if searchParams include filter keys
 * searchParams are FilterCategoryName or PRODUCT_TYPE_PARAM
 */
const searchParamsIncludeFilter = (searchParams: LocationQuery) => Object.keys(searchParams)
  .some((objectKey) => filterCategoryNames.includes(objectKey as FilterCategoryName) || objectKey === PRODUCT_TYPE_PARAM);

/**
 * from an object of searchParams,
 * ensure values to be arrays instead of strings
 */
const ensureValuesAreArrays = (obj: LocationQuery) : {[key: string]: string[]} => Object
  .entries(obj).reduce((acc, [
    key,
    value,
  ]) => ({
    ...acc,
    ...{ [key]: ensureArray(value) },
  }), {});

const findFilterCategoryByKey = (key: FilterCategoryName): FilterCategory | undefined => filterCategories.find(
  (filterGroup) => filterGroup.name === key,
);

// Reusing the findFilterByKey function
const findFilterByKey = (filters: Filter[], key: string): Filter | undefined => filters
  .find((f) => f.key === key)
  || filters.flatMap((f) => ('subItems' in f
    ? f.subItems
    : [])).find((f) => f.key === key);

const findFilterInGroup = (filterGroups: FilterGroup[], groupKey: FilterCategoryName, filterKey: string): Filter | undefined => {
  // Find the filter group by its key
  const filterGroup = filterGroups.find((group) => group.key === groupKey);

  if (!filterGroup) {
    return undefined; // Return undefined if the group with the specified key is not found
  }

  // Use the previously defined function to find the filter within the found group
  return findFilterByKey(filterGroup.filters, filterKey);
};

export {
  areAllEnabledSubItemsSelected,
  createFilterWithEnabledStateForFacets,
  ensureValuesAreArrays,
  findFilterCategoryByKey,
  findFilterInGroup,
  getComplementaryColor,
  isNestedFilter,
  searchParamsIncludeFilter,
};
